import { Router as VaadinRouter } from '@vaadin/router';
import { APP_BASE_PATH } from '../globals';

export class Router {
  static go(path) {
    if (path.charAt(0) === '/') {
      path = path.substr(1);
    }
    VaadinRouter.go(`${APP_BASE_PATH}${path}`);
  }
}
